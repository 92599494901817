import type { ErrorInfo, ReactHTML } from "react";
import { Component, createContext } from "react";
import { Log } from "@/src/util";

export type ContentModulePlacement = Partial<
  Pick<CSSStyleDeclaration, "gridArea" | "gridColumn" | "gridRow">
> & {
  id?: string | undefined;
  isAboveTheFold?: boolean | undefined;
  alternateBackground?: string;
  titleTagName?: keyof ReactHTML | undefined;
  isTitleSrOnly?: boolean | undefined;
  position?: string | undefined;
};

interface Props {
  tagName?: keyof ReactHTML | undefined;
  className?: string | undefined;
  placement: ContentModulePlacement;
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export const ContentModuleContext = createContext<ContentModulePlacement>({});

export class ContentModule extends Component<Props, State> {
  state: State = {};

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "ContentModule.tsx",
      "componentDidCatch",
      [error, errorInfo]
    );
  }

  render() {
    if (this.state.error) {
      return (
        <div
          id={this.props.placement.id}
          onDoubleClick={() => {
            // eslint-disable-next-line no-console
            console.dir(this.state);
          }}
        >
          Something went wrong...
        </div>
      );
    }

    const {
      tagName: TagName = "section",
      className,
      placement,
      placement: { id },
    } = this.props;

    return (
      <ContentModuleContext.Provider value={placement}>
        <TagName id={id} className={className}></TagName>
      </ContentModuleContext.Provider>
    );
  }
}

export default ContentModule;
