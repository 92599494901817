//@refresh reset

import { SEOModule } from "@/src/component/cms";
import { Listing } from "@/src/component/view";
import { SEARCH_ID_INITIALIZE } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { BlaceV2API, StrapiCMSAPI } from "@/src/service";
import { BlaceV2Type, StrapiCMSType } from "@/src/type";
import { StringHelper, uniqueId } from "@/src/util";
import type { GetServerSideProps, NextPage } from "next";

interface ListingPageProps {
  search: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  allGeoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  query: BlaceV2Type.AzureSearchQueryType.Request;
  globalWebConfig: StrapiCMSType.GlobalWebConfigResponse;
  locale: string;
  perPage: number;
  page: number;
  searchParams: string;
}

const ListingPage: NextPage<ListingPageProps> = ({
  search,
  allGeoPoints,
  query,
  perPage,
  page,
  globalWebConfig,
}) => {
  return (
    <>
      <SEOModule path="/d/en/discover" globalWebConfig={globalWebConfig} />
      <Listing
        initialSearch={search}
        initialQuery={query}
        initialPerPage={perPage}
        initialPage={page}
        initialGeoPoints={allGeoPoints}
      />
    </>
  );
};

export default ListingPage;

export const getServerSideProps: GetServerSideProps = async context => {
  const locale: string = StringHelper.checkIsStringOrIsNull(
    context?.params?.locale,
    "en"
  );
  const GlobalWebConfigResponse = await StrapiCMSAPI.getGlobalWebConfig(locale);
  const filters: Record<string, any> =
    SearchLogic.deconstructQueryParamsKeyValue(context.query);

  // const perPage = 20; - this number needed when advertisement cards are present
  // check `/src/component/partial/ListingGrid/resultsToCards.tsx` - `isAdvertisingCardSupported`
  const perPage = 24;
  const initialPage = 1;
  const page = Number(context.query.page) || initialPage;
  const query = SearchLogic.defaultQuery(perPage);

  query.filter = SearchLogic.constructFilter(filters);
  query.sessionId = StringHelper.checkIsStringOrIsNull(
    context.query.searchTerm,
    query.sessionId ?? uniqueId()
  );
  query.skip = page * perPage - perPage;

  let searchTerm = undefined;
  let queryType = undefined;
  if ((context.query.searchTerm ?? "").length > 0) {
    try {
      //https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error
      searchTerm = Buffer.from(
        `${context.query.searchTerm}`,
        "base64"
      ).toString();
      queryType =
        Buffer.from(`${context.query.searchQueryType}`, "base64").toString() ===
        "simple"
          ? "simple"
          : "full";
      query.search = `${searchTerm}${queryType === "full" ? "*" : ""}`;
      query.queryType = queryType === "simple" ? "simple" : "full";
    } catch (err) {
      //ignore
    }
  }

  const searchResponse = await BlaceV2API.SearchServiceV2.postSearchQuery(
    query
  );
  if (searchResponse.status !== 200) {
    //TODO: this is an endless loop that needs to be fixed
    return {
      redirect: {
        destination: "/d/en/discover?error=noDataForSlug",
        permanent: false,
      },
    };
  }

  const allGeoPoints = await BlaceV2API.SearchServiceV2.postSearchQuery(
    SearchLogic.getAllGeoPoints("dataType eq 'venue'")
  );

  return {
    props: {
      search: searchResponse.body?.payload ?? {},
      query,
      globalWebConfig: GlobalWebConfigResponse.response,
      locale,
      perPage,
      page,
      allGeoPoints: allGeoPoints.body?.payload ?? {},
      filters,
      searchId: context?.query?.searchId ?? SEARCH_ID_INITIALIZE,
      initGlobalSearch: {
        searchTerm: searchTerm ?? "",
        queryType: queryType ?? "full",
      },
    },
  };
};
