//https://developers.google.com/search/docs/appearance/site-names
//continue work on schema.org:
//https://developers.google.com/search/docs/appearance/structured-data/vacation-rental
import Head from "next/head";
import { SEO_URL } from "@/src/const";
import { StrapiCMSType } from "@/src/type";

const DESCRIPTION = "BLACE is an online marketplace for booking unique event spaces near you. We provide our clients venues for creating memorable experiences from corporate events and brand activations to private chef dinners and world class conferences.";

interface SEOModuleProps {
  name?: string;
  nameAddition?: string;
  dashedName?: boolean;
  description?: string;
  imageURL?: string;
  path: string;
  globalWebConfig: StrapiCMSType.GlobalWebConfigResponse;
}

function SEOModule({
  name = "Book premier venues and expert vendors for every event",
  nameAddition,
  dashedName,
  description = DESCRIPTION,
  imageURL = "https://blace-prod.imgix.net/blaceLogo192.png",
  path = "",
  globalWebConfig,
}: SEOModuleProps) {
  let desc = description;
  if (!description || description === "null") {
    desc = DESCRIPTION;
  }

  const byName = globalWebConfig?.data?.attributes?.name ?? "BLACE";
  const completeName = nameAddition ? `${name} - ${nameAddition}` : name;
  const title = dashedName ? `${completeName} | by ${byName}` : `${completeName} by ${byName}`;
  return (
    <Head>
      <title>{`${title}`}</title>
      <meta name="description" content={desc} />
      <meta name="author" content={byName} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imageURL} />
      <meta property="og:site_name" content={byName} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${name} by ${byName}`} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={imageURL} />
      <link rel="canonical" hrefLang="en" href={`${SEO_URL}${path}`} />
      <link rel="alternate" hrefLang="en" href={`${SEO_URL}${path}`} />
      <link rel="og:url" hrefLang="en" href={`${SEO_URL}${path}`} />
      <link rel="twitter:url" hrefLang="en" href={`${SEO_URL}${path}`} />
      <link
        rel="icon"
        href="https://blace-prod.imgix.net/blaceFavicon.ico"
      ></link>
      <meta name="robots" content="index,follow" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: byName,
            alternateName: [byName],
            headline: title,
            url: SEO_URL,
            image: [imageURL],
            description: desc
          }),
        }}
      />
    </Head>
  );
}

export default SEOModule;
